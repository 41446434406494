// import axios from "../common-axios";
import axios from "axios";

class ProductService {



  // product-item
  get_item() { return axios.get("/product/item/"); }
  getStock() { return axios.get("/product/item-stock/")}
  create_item(data: any) { return axios.post("/product/create-item/", data); }
  getByTitle(title: any) { return axios.get(`/product/get-item/?title=${title}`); }
  getStockByWareHouse(title: any, project: number) { return axios.get(`/product/get-stock-by-warehouse/?title=${title}&project_id=${project}`); }  // item-stock
  // get_item_stock() { return axios.get("/product/item-stock/"); }
  get_item_stock(title: string, project: number) { return axios.get(`/product/item-stock/?title=${title}&project_id=${project}`); }

  get_item_stock_project(id: any, item_type:string) { return axios.get(`/product/get-item-stock/${id}/?item_type=${item_type}`); }

  create_item_stock(data: any) {
    return axios.post("/product/create-item-stock/", data)

  }
  upload_file_in_stock(doc: any, id: any) {
    if (doc !== null) {
      let formData = new FormData();
      formData.append('id', id);
      formData.append('delivery_doc', doc);
      return axios.patch('/product/create-item-stock/', formData)
    }
  }
  create_item_stock_exit(data: any) { return axios.post("/product/create-item-stock-exit/", data); }

  upload_files_in_stock_exit(exit: any, gate: any, id: any) {
    if (exit !== null && gate !== null) {
      let formData = new FormData();
      formData.append('id', id);
      formData.append('exit_pass_doc', exit);
      formData.append('gate_pass_doc', gate);
      return axios.patch('/product/create-item-stock-exit/', formData)
    }
  }

  get_item_reqeust_in(id: any) { return axios.get("/product/item-request-in/" + id); }
  get_item_reqeust_out(id: any) { return axios.get("/product/item-request-out/" + id); }

  get_item_reqeust_in_report(id: any) { return axios.get("/product/get-item-stock-in-report/" + id); }

  post_item_reqeust_inout_report(data: any) { return axios.post("/product/get-item-stock-in-out-report/", data); }


  // catgories
  get_category(id: string) {
    if (id == '') { return axios.get(`/product/category/`); }
    else { return axios.get(`/product/category/${id}`); }
  }
  create_category(data: any) { return axios.post("/product/category/", data); }
  update_category(id: string, data: any) { return axios.put(`/product/category/${id}/`, data); }
  delete_category(id: string) { return axios.delete(`/product/category/${id}`); }
  category_by_title(title: string) { return axios.get(`/product/category/?title=${title}`); }

  // catgories_level_1
  get_sub_level_1_category(id: string) {
    if (id == '') { return axios.get(`/product/category-level-1/`); }
    else { return axios.get(`/product/category-level-1/${id}`); }
  }
  create_sub_level_1_category(data: any) { return axios.post("/product/category-level-1/", data); }
  update_sub_level_1_category(id: string, data: any) { return axios.put(`/product/category-level-1/${id}/`, data); }
  delete_sub_level_1_category(id: string) { return axios.delete(`/product/category-level-1/${id}`); }
  sub_level_1_category_title(title: string) { return axios.get(`/product/category-level-1/?title=${title}`); }

  // catgories_level_2
  get_sub_level_2_category(id: string) {
    if (id == '') { return axios.get(`/product/category-level-2/`); }
    else { return axios.get(`/product/category-level-2/${id}`); }
  }
  create_sub_level_2_category(data: any) { return axios.post("/product/category-level-2/", data); }
  update_sub_level_2_category(id: string, data: any) { return axios.put(`/product/category-level-2/${id}/`, data); }
  delete_sub_level_2_category(id: string) { return axios.delete(`/product/category-level-2/${id}`); }
  sub_level_2_category_title(title: string) { return axios.get(`/product/category-level-2/?title=${title}`); }

}

export default new ProductService();